<template>
  <div class="PublicShare_div">
    <div class="share_div_box" id="share-2"></div>
  </div>
</template>

<script>
export default {
  // name: "",
  data() {
    return {
    }
  },
  computed: {},

  components: {},

  methods: {
  },

  destroyed() {
  },

  mounted() {
    socialShare('#share-2', { sites: ['qzone', 'qq', 'weibo', 'wechat'] });
  },

  created() { },
}
</script>

<style lang="scss" scoped>
@import "./share-dist/css/share.min.css";
</style>
<style lang="scss">
.PublicShare_div {
  display: flex;
  align-items: center;
  .share_div_box {
    display: flex;
    align-items: center;
    .social-share-icon {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      margin-right: 20px;
    }
  }
}
</style>

